<template>
  <Page has-actions>
    <template slot="actions">
      <b-dropdown
        ref="dropdown"
        aria-role="list"
        position="is-bottom-left"
        :close-on-click="false"
        scrollable
        max-height="400px"
        append-to-body
      >
        <div slot="trigger">
          <b-button
            icon-left="dots-vertical"
            :size="$device.mobile ? 'is-small' : ''"
            type="is-default"
            rounded
          />
        </div>

        <b-dropdown-item
          v-if="$can('read', 'orders.analytics.export')"
          aria-role="listitem"
          @click="exportModal=true"
        >
          <b-icon
            icon="download"
            size="is-small"
          />
          {{ $t("export_excel") }}
        </b-dropdown-item>
      </b-dropdown>
    </template>

    <ranking-view
      :total="total"
      :pagination-object="pagination"
      :stats="stats"
      :stats-loading="stats_loading"
      :headers="stats_headers"
      :warehouses="warehouses"
      :origin-sites="originSites"
      :brands="brands"
      :client-types="clientTypes"
      :salesmen="salesmen"
      :origin-categories="originCategories"
      :original-data="originalData"
      @getData="getStats"
    />

    <b-modal
      v-model="exportModal"
      :width="420"
    >
      <Card>
        <div slot="header">
          <div class="is-size-5 px py-4">
            <b>{{ $t("which_data_to_export") }}</b>
          </div>
          <hr class="my-0">
        </div>
        <div class="columns is-multiline is-1 p-1">
          <div class="column is-12">
            <b-button
              type="is-primary"
              :loading="stats_loading"
              :disabled="stats_loading"
              expanded
              @click="$bus.$emit('download-stats', pagination.perPage)"
            >
              {{ $t("current_results") }}
            </b-button>
          </div>
          <div class="column is-12">
            <b-button
              type="is-primary"
              :loading="stats_loading"
              :disabled="stats_loading"
              expanded
              @click="$bus.$emit('download-stats', 'all')"
            >
              {{ $t("all_results") }}
            </b-button>
          </div>
        </div>
      </Card>
    </b-modal>
  </Page>
</template>

<script>
import rankingView from "@/components/modules/analytics/ranking/index.vue";

export default {
  name: "Ranking",
  components: {
    rankingView
  },
  data() {
    return {
      total: 0,
      pagination: {
        perPage: 200,
        page: 1
      },
      warehouses: [],
      originSites: [],
      brands: [],
      clientTypes: [],
      salesmen: [],
      originCategories: [],
      stats: [],
      originalData: [],
      stats_loading: false,
      exportModal: false,
      stats_headers: [
      {
          text: this.$tc("id", 1),
          value: "id",
          width: 100
        },
        {
          text: this.$tc("name", 1),
          value: "tld",
          width: 150
        },
        {
          text: this.$tc("order_short", 2),
          value: "num_orders"
        },
        {
          text: this.$t("gross_revenue"),
          value: "gross_revenue"
        },
        {
          text: this.$t("discount"),
          value: "discount"
        },
        {
          text: this.$t("net_revenue"),
          value: "net_revenue"
        },
        {
          text: this.$t("cashback"),
          value: "cashback"
        },
        {
          text: this.$t("purchase_price"),
          value: "purchase_price"
        },
        {
          text: this.$t("gross_margin"),
          value: "gross_margin"
        },
        {
          text: this.$t("gross_margin_ratio"),
          value: "gross_margin_ratio"
        },
        {
          text: this.$t("avg_cart"),
          value: "avg_cart"
        },
        {
          text: this.$t("qty"),
          value: "quantity"
        },
        {
          text: this.$t("qty_free"),
          value: "quantity_free"
        },
        {
          text: "ABC",
          value: "abc",
          align: "center"
        }
      ]
    };
  },
  created() {
    this.updateTitle(this.$i18n.t("ranking"));
  },
  methods: {
    getStats: function(filters, options, download, perPage) {
      this.stats_loading = true;
      // adding selected warehouse to params
      let api_params = "?warehouse_id=" + (options.warehouse_id || "");

      // adding date range to params
      if (options.date_filter.from && options.date_filter.to) {
        api_params +=
          "&date_from=" +
          options.date_filter.from +
          "&date_to=" +
          options.date_filter.to;
      }

      // adding selected ranking value to params
      if (options.selected_ranking)
        api_params += "&ranking=" + options.selected_ranking;

      // adding pagination
      api_params += `&page=${options.pagination.page}&per_page=${perPage ? perPage : options.pagination.perPage}`;

      // adding filters
      let filter = [];
      if (filters.brands.length) {
        filter.push({
          id: "brands",
          selected: filters.brands
        });
      }
      if (filters.sites.length) {
        let sites = [];
        let slds = [];
        for (let f of filters.sites) {
          if(typeof f == 'number') sites.push(f);
          else slds.push(parseInt(f.substring(1)));
        }
        if (sites.length) {
          filter.push({
            id: "sites",
            selected: sites
          });
        }
        if (slds.length) {
          filter.push({
            id: "slds",
            selected: slds
          });
        }
      }
      if (filters.slds.length) {
        filter.push({
          id: "slds",
          selected: filters.slds
        });
      }
      if (filters.salesmen.length) {
        filter.push({
          id: "salesmen",
          selected: filters.salesmen
        });
      }
      if (filters.categories.length) {
        filter.push({
          id: "categories",
          selected: filters.categories
        });
      }
      if (filters.client_types) {
        filter.push({
          id: "client_type",
          selected: [filters.client_types]
        });
      }
      // encoding filter values
      if (filter.length)
        api_params += `&filters=${btoa(JSON.stringify(filter))}`;

      // adding download option to params
      if (download) {
        api_params += `&export=1`;
      }

      this.$axios
        .get("analytics/ranking" + api_params)
        .then(res => {
          if (download) {
            // downloading recieved file to user machine
            const url = window.URL.createObjectURL(
              new Blob([res.data.file], {
                type: "application/vnd.ms-excel"
              })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", res.data.filename);
            document.body.appendChild(link);
            link.click();
            this.exportModal = false;
          } else {
            // applying new data recieved from API
            let data = res.data.list;
            this.warehouses = res.data.filters.warehouses;
            this.clientTypes = res.data.filters.client_types;
            this.brands = res.data.filters.brands;
            this.originCategories = res.data.filters.categories;
            this.originSites = res.data.filters.sites;
            this.salesmen = res.data.filters.salesmen;
            this.originalData = data;
            this.total = res.data.pagination.total;
            this.pagination.page = res.data.pagination.current;
            let summary = res.data.summary;
            let stats = [];

            // modeling data to show in data-table
            for (let i = 0; i < data.length; i++) {
              let elt = data[i];
              let obj = {
                id: elt.id,
                label: elt.label,
                num_orders: elt.stats.num_orders,
                gross_revenue: elt.stats.gross_revenue.formatted,
                discount: elt.stats.discount.formatted,
                net_revenue: elt.stats.net_revenue.formatted,
                cashback: elt.stats.cashback.formatted,
                avg_cart: elt.stats.avg_cart.formatted,
                quantity: elt.stats.quantity,
                abc: elt.stats.abc
              };
              if (elt.stats.purchase_price)
                obj.purchase_price = elt.stats.purchase_price.formatted;
              if (elt.stats.gross_margin)
                obj.gross_margin = elt.stats.gross_margin.formatted;
              if (elt.stats.gross_margin_ratio)
                obj.gross_margin_ratio = elt.stats.gross_margin_ratio;
              stats.push(obj);
            }
            let sum = {
              id: "summary",
              label: this.$t("total"),
              num_orders: summary.num_orders,
              gross_revenue: summary.gross_revenue.formatted,
              discount: summary.discount.formatted,
              net_revenue: summary.net_revenue.formatted,
              cashback: summary.cashback.formatted,
              avg_cart: summary.avg_cart.formatted,
              quantity: summary.quantity,
              abc: { value: "-", letter: "" }
            };
            if (summary.purchase_price)
              sum.purchase_price = summary.purchase_price.formatted;
            if (summary.gross_margin)
              sum.gross_margin = summary.gross_margin.formatted;
            if (summary.gross_margin_ratio)
              sum.gross_margin_ratio = summary.gross_margin_ratio;
            stats.push(sum);
            this.stats = JSON.parse(JSON.stringify(stats));
          }
        })
        .catch(e => this.clientError(e))
        .finally(() => (this.stats_loading = false));
    }
  }
};
</script>

